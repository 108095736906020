export function initCheckIllustrationCalendar() {
    let isCalendarIllustrationVisible = getComputedStyle(document.documentElement)
        .getPropertyValue('--isCalendarIllustrationVisible')
        .trim()
        .replace(/['"]+/g, '') || '';
    const elementToHideInCalendar = document.querySelector(".js-calendar");
    if (!elementToHideInCalendar)
        return;
    if (Number(isCalendarIllustrationVisible) === 0) {
        elementToHideInCalendar.classList.add('hidden');
    }
}
