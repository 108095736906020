const isHomePage = document.querySelector('.homepage') !== null;
/**
 * @author Manuel Sanchez <manuel@webschuppen.com>
 * @version 1.0.0
 *
 * @description
 * This function will create a line on the top of the page without animation
 * The animation will only take place in the home page
 * We are using pace --> web/packages/ws_bootstraptheme_layout/Resources/Private/Templates/Page/Homepage.html
 *
 * @example
 * initPaceWithoutAnimation();
 */
export const initPaceWithoutAnimation = () => {
    if (isHomePage === false) {
        const pace = document.createElement('div');
        pace.classList.add('pace');
        const paceProgress = document.createElement('div');
        paceProgress.classList.add('pace-progress');
        paceProgress.classList.add('pace-finished');
        pace.appendChild(paceProgress);
        document.body.insertBefore(pace, document.body.firstChild);
    }
};
