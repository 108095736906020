/**
 * Add a class to the field when the form is submitted
 * @param selector The selector of the field, must be an input, select or textarea
 */
export default class AddClassOnSubmit {
    constructor(selector, className) {
        this.selector = selector;
        this.className = className;
        this.element = document.querySelector(selector);
        const submitButton = document.querySelector('button[type="submit"]');
        submitButton.addEventListener('click', () => {
            this.onSubmitButtonClick();
        });
        if (!this.element) {
            console.error('RequiredField: Element not found', selector);
            return;
        }
    }
    onSubmit() {
        this.element.classList.add(this.className);
    }
    onSubmitFailed() {
        this.element.classList.add(this.className);
    }
    onSubmitButtonClick() {
        this.element.classList.add(this.className);
    }
}
