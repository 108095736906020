import FormErrorController from '../FormErrorController';
export class ShowErrorIfNotOneChecked {
    constructor(selectors) {
        this.selectors = selectors;
        this.elements = [];
        this.elements = this.selectors.map((selector) => {
            const element = document.querySelector(selector);
            if (element instanceof HTMLInputElement) {
                return element;
            }
            else {
                return null;
            }
        })
            .filter((element) => element);
        if (this.elements.length === 0) {
            console.error('ShowErrorIfNotOneChecked: No elements found with selectors', this.selectors);
        }
    }
    start() {
        for (const element of this.elements) {
            element.addEventListener('change', () => {
                this.reportValidity();
            });
        }
    }
    reportValidity() {
        const isAtLeastOneChecked = this.elements.some((element) => element.checked);
        if (isAtLeastOneChecked) {
            // all good
            FormErrorController.removeError(this.elements[0]);
        }
        else {
            const errorMessage = 'Bitte kreuzen Sie bei Punkt 2. entsprechend an. Ohne Angaben und Nachweise ist keine Ermäßigung möglich.';
            FormErrorController.addError(this.elements[0], errorMessage);
            return errorMessage;
        }
    }
    onSubmit() {
        if (this.elements.length === 0) {
            return console.error('ShowErrorIfNotOneChecked: No elements found with selectors. Not checking validity.');
        }
        return this.reportValidity();
    }
}
