export default class ScrollUpAtSuccessMessage {
    constructor(selector) {
        this.selector = selector;
    }
    start() {
        const form = document.querySelector(this.selector);
        const heading = document.querySelector('h1');
        if (form.tagName === 'FORM') {
            // there is no success message yet
        }
        else {
            setTimeout(() => {
                if (heading)
                    return heading.scrollIntoView();
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, 100);
        }
    }
}
