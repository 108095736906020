var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import FormUtils from './FormUtils';
import GeschwisterBonus from './GeschwisterBonus';
import KidsFieldset from './KidsFieldset';
import SchulgeldErmaessigung from './SchulgeldErmaessigung';
const COMMON_ID_GESCHWISTER = 'geschwisterbonus-1208';
const COMMON_ID_SCHULDGELDERMAESSIGUNG = 'schulgeldermaessigung-1207';
var FORM_PATHS;
(function (FORM_PATHS) {
    FORM_PATHS["GESCHWISTERBONUS"] = "/geschwisterbonus";
    FORM_PATHS["SCHULDGELDERMAESSIGUNG"] = "/schulgeldermaessigung";
})(FORM_PATHS || (FORM_PATHS = {}));
const { pathname } = new URL(window.location.href);
FormUtils.hideSearchOnFormPages([
    FORM_PATHS.GESCHWISTERBONUS,
    FORM_PATHS.SCHULDGELDERMAESSIGUNG
]);
export const initPDFForm = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        hideUploadIfNotSelected();
    }
    catch (e) {
        console.error(e);
    }
    // FormUtils.removeAllRequiredAttributes();
    autofillHelper();
    if (pathname == FORM_PATHS.GESCHWISTERBONUS) {
        try {
            new GeschwisterBonus();
        }
        catch (e) {
            console.error('Form failed to initialize', e);
        }
        handleFieldsets();
        replaceStringWithLink('.checkbox-terms', 'Datenschutzbedingungen', 'https://kgs-hh.ddev.site/datenschutzerklaerung');
    }
    if (pathname == FORM_PATHS.SCHULDGELDERMAESSIGUNG) {
        try {
            new SchulgeldErmaessigung();
        }
        catch (e) {
            console.error('Form failed to initialize', e);
        }
        handleFieldsets();
        toggleCheckBoxContent({
            selector: `#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-checkbox-3`,
            content: `${COMMON_ID_SCHULDGELDERMAESSIGUNG}-fieldset-7`
        });
        manageConditionalCheckboxes({
            checkbox1Selector: `#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-checkbox-1`,
            checkbox2Selector: `#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-checkbox-2`,
            content1Selector: `#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-fieldset-8`,
            content2Selector: `#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-fieldset-9`
        });
        replaceStringWithLink('.checkbox-terms', 'Datenschutzbedingungen', 'https://kgs-hh.ddev.site/datenschutzerklaerung');
        enforceSingleCheckbox('.checkboxes-no-income', 'In dieser Kategorie kann jeweils nur ein Kontrollkästchen aktiv sein. Befinden Sie sich vielleicht im Dienst des JobCenters?');
        addSubmitButtonLoadingState();
    }
});
/**
 * Toggles the content of a checkbox.
 *
 * @param selector - The selector of the checkbox.
 * @param content - The content to be toggled.
 */
const toggleCheckBoxContent = ({ selector, content }) => {
    const checkbox = document.querySelector(selector);
    const contentElement = document.querySelector(`#${content}`);
    if (!checkbox || !contentElement)
        return;
    // Initial check to handle the default state
    contentElement.style.display = checkbox.checked ? 'block' : 'none';
    checkbox.addEventListener('change', () => {
        contentElement.style.display = checkbox.checked ? 'block' : 'none';
    });
};
/**
 * Handles the fieldsets in the document.
 * Allows to add more fieldsets and to hide them.
 */
function handleFieldsets() {
    let FIELDSET_KIDS = [];
    if (pathname == FORM_PATHS.GESCHWISTERBONUS) {
        FIELDSET_KIDS = [
            `${COMMON_ID_GESCHWISTER}-fieldset-2`,
            `${COMMON_ID_GESCHWISTER}-fieldset-3`,
            `${COMMON_ID_GESCHWISTER}-fieldset-4`,
            `${COMMON_ID_GESCHWISTER}-fieldset-5`,
            `${COMMON_ID_GESCHWISTER}-fieldset-6`
        ];
    }
    if (pathname == FORM_PATHS.SCHULDGELDERMAESSIGUNG) {
        FIELDSET_KIDS = [
            `${COMMON_ID_SCHULDGELDERMAESSIGUNG}-fieldset-2`,
            `${COMMON_ID_SCHULDGELDERMAESSIGUNG}-fieldset-3`,
            `${COMMON_ID_SCHULDGELDERMAESSIGUNG}-fieldset-4`,
            `${COMMON_ID_SCHULDGELDERMAESSIGUNG}-fieldset-5`,
            `${COMMON_ID_SCHULDGELDERMAESSIGUNG}-fieldset-6`
        ];
    }
    if (FIELDSET_KIDS.length === 0) {
        return console.warn('Cancelled handleFieldsets() because no FIELDSET_KIDS were found.');
    }
    let nextFieldset = null;
    // let nextFieldset = null;
    let initiallyVisibleCount = 1;
    if (pathname == FORM_PATHS.GESCHWISTERBONUS) {
        initiallyVisibleCount = 2;
    }
    const fieldsets = [];
    for (let i = FIELDSET_KIDS.length - 1; i >= 0; i--) {
        const fieldset = FIELDSET_KIDS[i];
        const isLastFieldset = FIELDSET_KIDS.length - 1 === i;
        // hide all fieldsets but the first one
        try {
            nextFieldset = new KidsFieldset(fieldset, i, {
                isLastFieldset,
                fieldsets,
                initiallyVisibleCount
            });
            fieldsets[i] = nextFieldset;
        }
        catch (e) {
            console.error(e);
        }
    }
}
/**
 * Manages the conditional checkboxes and their corresponding content elements.
 * @param {{
 *   checkbox1Selector: string;
 *   checkbox2Selector: string;
 *   content1Selector: string;
 *   content2Selector: string;
 * }} options - The options object containing the selectors for the checkboxes and content elements.
 */
const manageConditionalCheckboxes = ({ checkbox1Selector, checkbox2Selector, content1Selector, content2Selector }) => {
    const checkbox1 = document.querySelector(checkbox1Selector);
    const checkbox2 = document.querySelector(checkbox2Selector);
    const content1 = document.querySelector(content1Selector);
    const content2 = document.querySelector(content2Selector);
    if (!checkbox1 || !checkbox2 || !content1 || !content2) {
        console.error('One or more elements not found');
        return;
    }
    const uncheckChildCheckboxes = (parentSelector) => {
        const childCheckboxes = document.querySelectorAll(`${parentSelector} input[type='checkbox']`);
        childCheckboxes.forEach((checkbox) => {
            checkbox.checked = false;
            const changeEvent = new Event('change');
            const clickEvent = new Event('click');
            checkbox.dispatchEvent(changeEvent);
            checkbox.dispatchEvent(clickEvent);
        });
    };
    const getLabel = (element) => element.closest('label') || element;
    const updateStates = () => {
        const label1 = getLabel(checkbox1);
        const label2 = getLabel(checkbox2);
        if (checkbox1.checked) {
            uncheckChildCheckboxes(content2Selector);
            label2.style.display = 'none';
            content1.style.display = 'block';
            content2.style.display = 'none';
        }
        else if (checkbox2.checked) {
            uncheckChildCheckboxes(content1Selector);
            label1.style.display = 'none';
            content2.style.display = 'block';
            content1.style.display = 'none';
        }
        else {
            uncheckChildCheckboxes(content2Selector);
            uncheckChildCheckboxes(content1Selector);
            label1.style.display = 'block';
            label2.style.display = 'block';
            content1.style.display = 'none';
            content2.style.display = 'none';
        }
    };
    checkbox1.addEventListener('change', updateStates);
    checkbox2.addEventListener('change', updateStates);
    // Initialize the states based on the current checkbox states
    updateStates();
};
const autofillHelper = () => {
    // Set postal code field type
    if (pathname == FORM_PATHS.GESCHWISTERBONUS) {
        const postalCodeField = document.querySelector(`#${COMMON_ID_GESCHWISTER}-text-3`);
        if (postalCodeField) {
            postalCodeField.autocomplete = 'postal-code';
            postalCodeField.type = 'number';
        }
        const cityField = document.querySelector(`#${COMMON_ID_GESCHWISTER}-text-32`);
        if (cityField) {
            cityField.autocomplete = 'address-level2';
        }
    }
    if (pathname == FORM_PATHS.SCHULDGELDERMAESSIGUNG) {
        const postalCodeField = document.querySelector(`#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-text-3`);
        if (postalCodeField) {
            postalCodeField.autocomplete = 'postal-code';
            postalCodeField.type = 'number';
        }
        const cityField = document.querySelector(`#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-text-37`);
        if (cityField) {
            cityField.autocomplete = 'address-level2';
        }
        const bdayField = document.querySelector(`#${COMMON_ID_SCHULDGELDERMAESSIGUNG}-text-9`);
        if (bdayField) {
            bdayField.autocomplete = 'bday';
        }
    }
};
/**
 * Replaces a specified string in an element's text with a link.
 * @param selector The CSS selector of the element to search within.
 * @param searchString The string to be replaced.
 * @param href The href attribute for the link.
 */
function replaceStringWithLink(selector, searchString, href) {
    const element = document.querySelector(selector);
    if (element) {
        // Check that any of the children contain the search string
        const childNodes = Array.from(element.childNodes);
        childNodes.forEach((child) => {
            if (child.nodeType === Node.TEXT_NODE &&
                child.textContent &&
                child.textContent.includes(searchString)) {
                const regex = new RegExp(`(${searchString})`, 'gi');
                const replacement = document.createElement('span');
                replacement.innerHTML = child.textContent.replace(regex, `<a target="_blank" href="${href}">$1</a>`);
                child.replaceWith(replacement);
            }
            else if (child.nodeType === Node.ELEMENT_NODE) {
                // If it's an element, we can safely use innerHTML
                const childElement = child;
                const regex = new RegExp(`(${searchString})`, 'gi');
                childElement.innerHTML = childElement.innerHTML.replace(regex, `<a target="_blank" href="${href}">$1</a>`);
            }
        });
    }
    else {
        console.warn(`Element with selector "${selector}" not found.`);
    }
}
/**
 * Enforces that only one checkbox can be active within a given selector.
 * If a second checkbox is attempted to be checked, it triggers an alert.
 *
 * @param selector - The CSS selector to target the container of checkboxes.
 * @param message - The message to be displayed in the alert.
 */
function enforceSingleCheckbox(selector, message) {
    const container = document.querySelector(selector);
    if (!container) {
        console.warn(`No element found with the selector: ${selector}`);
        return;
    }
    container.addEventListener('click', (event) => {
        const checkboxes = container.querySelectorAll('input[type="checkbox"]');
        const target = event.target;
        if (target.tagName !== 'INPUT' || target.type !== 'checkbox') {
            return;
        }
        const checkedCheckboxes = Array.from(checkboxes).filter((cb) => cb.checked);
        if (checkedCheckboxes.length > 1) {
            alert(message);
            event.preventDefault(); // Prevent the checkbox from being checked
            target.checked = false; // Uncheck the newly clicked checkbox
        }
    });
}
const hideUploadIfNotSelected = () => {
    const rows = Array.from(document.querySelectorAll('#schulgeldermaessigung-1207-fieldset-9 > .row'));
    const objects = rows
        .map((el) => {
        const checkboxes = Array.from(el.querySelectorAll('.checkbox')).map((el) => {
            const checkbox = el.querySelector('input[type=checkbox]');
            return checkbox;
        });
        const fileUploads = el.querySelectorAll('[type=file]');
        return {
            checkboxes,
            fileUploads
        };
    })
        .filter(({ checkboxes, fileUploads }) => {
        return checkboxes.length == 2 && fileUploads;
    });
    objects.forEach(({ checkboxes, fileUploads }) => {
        const setUploadDisplay = () => {
            if (checkboxes[0].checked) {
                fileUploads[0].parentElement.parentElement.style.display = 'block';
                fileUploads[0].required = true;
            }
            else {
                fileUploads[0].parentElement.parentElement.style.display = 'none';
                fileUploads[0].required = false;
            }
            if (checkboxes[1].checked) {
                fileUploads[1].parentElement.parentElement.style.display = 'block';
                fileUploads[1].required = true;
            }
            else {
                fileUploads[1].parentElement.parentElement.style.display = 'none';
                fileUploads[1].required = false;
            }
        };
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('click', () => {
                setUploadDisplay();
            });
        });
        setUploadDisplay();
    });
};
const addSubmitButtonLoadingState = () => {
    const form = document.querySelector('#schulgeldermaessigung-1207');
    form.addEventListener('formdata', (event) => {
        const target = event.target;
        const submitButton = target.querySelector('div.actions > nav > div > div > span > button');
        submitButton.disabled = true;
        submitButton.innerHTML = submitButton.innerHTML.replace('Absenden', 'Bitte warten...');
    });
    form.addEventListener('invalid', (event) => {
        const target = event.target;
        const submitButton = target.querySelector('div.actions > nav > div > div > span > button');
        submitButton.disabled = false;
        submitButton.classList.remove('.next.submit[type=submit]');
        submitButton.textContent = submitButton.textContent.replace('Bitte warten...', 'Absenden');
    });
};
