var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
export function initQuotesAnimation() {
    return __awaiter(this, void 0, void 0, function* () {
        const quotes = document.querySelectorAll('.quotes');
        if (quotes) {
            gsap.utils.toArray(quotes).forEach((element) => {
                gsap.registerPlugin(ScrollTrigger);
                const headline = element.querySelector('h2');
                const allQuotes = element.querySelectorAll('.quote');
                const elementTimeline = gsap.timeline({
                    defaults: { duration: 0.5, stagger: 0.15 },
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 92%',
                        end: 'bottom center'
                    }
                });
                elementTimeline.fromTo(headline, {
                    opacity: 0,
                }, {
                    opacity: 1,
                });
                elementTimeline.fromTo(allQuotes, {
                    opacity: 0,
                    y: 200
                }, {
                    opacity: 1,
                    y: 0
                });
            });
        }
    });
}
