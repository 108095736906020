import { ManageIban } from './ManageIban';
import FormErrorController from '../FormErrorController';
export default class RequireIBANIfAccountHolderSelected {
    constructor(inputSelector, accountHolderSelectSelector) {
        this.inputSelector = inputSelector;
        this.accountHolderSelectSelector = accountHolderSelectSelector;
    }
    reportValidity() {
        this.ibanInput.setAttribute('required', 'true');
        ManageIban.validateIBAN(this.ibanInput.value);
        const errorMessage = ManageIban.validateIBAN(this.ibanInput.value);
        if (errorMessage && this.ibanInput.value) {
            FormErrorController.addError(this.ibanInput, errorMessage);
        }
        else {
            FormErrorController.removeError(this.ibanInput);
        }
    }
    setAsOptional() {
        this.ibanInput.removeAttribute('required');
        FormErrorController.removeError(this.ibanInput);
    }
    start() {
        console.info('RequireIBANIfAccountHolderSelected: start');
        this.ibanInput = document.querySelector(this.inputSelector);
        this.accountHolderSelectElements = this.accountHolderSelectSelector
            .map(selector => document.querySelector(selector))
            .filter(Boolean);
        if (!this.ibanInput || !this.accountHolderSelectElements.length) {
            throw new Error('Input or checkbox element not found');
        }
        this.accountHolderSelectElements.forEach(select => {
            select.addEventListener('change', () => {
                if (this.accountHolderSelectElements.some(select => select.checked)) {
                    this.reportValidity();
                }
                else {
                    this.setAsOptional();
                }
            });
        });
    }
    onSubmit() {
        if (this.accountHolderSelectElements.some(select => select.checked)) {
            this.reportValidity();
        }
        else {
            this.setAsOptional();
        }
    }
}
