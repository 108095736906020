export default class FormUtils {
    static hideSearchOnFormPages(pages) {
        if (pages.includes(window.location.pathname)) {
            const mobileSearch = document.querySelector('.js-mobile-search');
            const desktopSearch = document.querySelector('.search-toggle.js-search');
            mobileSearch.style.display = 'none';
            desktopSearch.style.visibility = 'hidden';
        }
    }
    static turnOffAutocomplete(selectors) {
        selectors.forEach((selector) => {
            const element = document.querySelector(selector);
            if (!element)
                return;
            element.autocomplete = 'off-xyz';
        });
    }
    static prefillField(selector, value) {
        const field = document.querySelector(selector);
        if (!field)
            return console.error('prefillField: Element not found', selector);
        field.value = value;
    }
    static getAge(date) {
        const today = new Date();
        let age = today.getFullYear() - date.getFullYear();
        const month = today.getMonth() - date.getMonth();
        if (month < 0 || (month === 0 && today.getDate() < date.getDate())) {
            age--;
        }
        return age;
    }
    static isElementDisplayed(element) {
        return element.offsetParent !== null &&
            element.getBoundingClientRect().width > 0 && element.getBoundingClientRect().height > 0;
    }
}
