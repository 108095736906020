var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as smoothscroll from 'smoothscroll-polyfill';
export function initSmoothScroll() {
    return __awaiter(this, void 0, void 0, function* () {
        // TODO: This is a hack to get the smoothscroll polyfill to work.
        smoothscroll.polyfill();
        function scrollToTop() {
            window.scroll({ top: 0, left: 0, behavior: 'smooth' });
        }
        function initScrollButton() {
            const backToTopButton = document.querySelector('.scrollToTop');
            backToTopButton.addEventListener('click', scrollToTop);
            backToTopButton.style.display = 'none';
        }
        initScrollButton();
    });
}
function scrollTopButtonAppear() {
    const backToTopButton = document.querySelector('.scrollToTop');
    if (window.pageYOffset > 50) {
        if (!backToTopButton.classList.contains('back-to-top-btn-entry')) {
            backToTopButton.classList.remove('back-to-top-btn-exit');
            backToTopButton.classList.add('back-to-top-btn-entry');
            backToTopButton.style.display = 'block';
        }
    }
    else {
        if (backToTopButton.classList.contains('back-to-top-btn-entry')) {
            backToTopButton.classList.remove('back-to-top-btn-entry');
            backToTopButton.classList.add('back-to-top-btn-exit');
            setTimeout(function () {
                backToTopButton.style.display = 'none';
            }, 250);
        }
    }
}
window.addEventListener('scroll', scrollTopButtonAppear);
