export function initSearch() {
    const searchBox = document.querySelector('.tx-indexedsearch-searchbox-sword');
    if (!searchBox)
        return;
    const searchButton = document.querySelector('.search-toggle');
    const mobileSearchButton = document.querySelector('#mobile-search-button');
    const navListItems = document.querySelector('.nav-list-items');
    const originalSubmitButtonDesktop = document.querySelector('.js-desktop-search .tx-indexedsearch-searchbox-button');
    const originalSubmitButtonMobile = document.querySelector('.js-mobile-search .tx-indexedsearch-searchbox-button');
    if (!navListItems)
        return;
    const firstNavItem = navListItems.children[0];
    let originalSubmitClicked = false;
    let absoluteLeftPositionNavItem = firstNavItem.getBoundingClientRect().left;
    let absoluteLeftPositionNavItems = navListItems.getBoundingClientRect().left;
    let relativeLeftPosition = absoluteLeftPositionNavItem - absoluteLeftPositionNavItems;
    searchBox.style.left = relativeLeftPosition + 'px';
    const rePositionSearchBox = () => {
        absoluteLeftPositionNavItem = firstNavItem.getBoundingClientRect().left;
        absoluteLeftPositionNavItems = navListItems.getBoundingClientRect().left;
        relativeLeftPosition = absoluteLeftPositionNavItem - absoluteLeftPositionNavItems;
        searchBox.style.left = relativeLeftPosition + 'px';
    };
    window.onresize = () => rePositionSearchBox();
    window.onscroll = () => rePositionSearchBox();
    searchButton.addEventListener('click', (event) => {
        rePositionSearchBox();
        const width = searchButton.getBoundingClientRect().left +
            (searchButton.getBoundingClientRect().width + 8) -
            firstNavItem.getBoundingClientRect().left;
        searchBox.style.width = width + 'px';
        if (!searchBox.classList.contains('tx-indexedsearch-searchbox-sword--visible')) {
            searchBox.classList.add('tx-indexedsearch-searchbox-sword--visible');
            searchBox.focus();
            searchBox.style.padding = '0.7rem 0 0.6rem 0.8rem';
            setTimeout(() => {
                searchBox.placeholder = 'Suche';
            }, 500);
        }
        else {
            originalSubmitButtonDesktop.click();
        }
        event.stopPropagation();
    });
    mobileSearchButton.addEventListener('click', (event) => {
        originalSubmitButtonMobile.click();
        event.stopPropagation();
    });
    document.addEventListener('click', (event) => {
        if (!searchBox.contains(event.target) && !originalSubmitClicked) {
            searchBox.classList.remove('tx-indexedsearch-searchbox-sword--visible');
            // searchBox.style.left = '0';
            searchBox.style.width = '0';
            // padding: 0.5rem 1rem;
            searchBox.style.padding = '';
            searchBox.value = '';
            searchBox.placeholder = '';
        }
        originalSubmitClicked = false;
    });
    originalSubmitButtonDesktop.addEventListener('click', () => {
        originalSubmitClicked = true;
    });
}
