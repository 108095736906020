export class PresetValidityMessage {
    constructor(selector, message) {
        this.selector = selector;
        this.message = message;
    }
    start() {
        const element = document.querySelector(this.selector);
        this.element = element;
        this.element.addEventListener('invalid', () => {
            if (!this.element.validity.valid) {
                this.element.setCustomValidity(this.message);
            }
        });
        this.element.addEventListener('input', () => {
            this.element.setCustomValidity('');
        });
    }
    onSubmit() {
        if (!this.element.validity.valid) {
            this.element.setCustomValidity(this.message);
        }
    }
}
