const MAX_NUMBER_OF_ELEMENTS_NOT_TO_BREAK = 7;
export function initCheckAmountMenuItem() {
    const navList = document.querySelector('.nav-list-items');
    if (!navList)
        return;
    const navListItems = Array.from(navList.querySelectorAll('.nav-item'));
    const hideFirstElement = () => {
        const windowWidth = window.innerWidth;
        const shouldFirstElementBeHidden = windowWidth > 1024 && windowWidth < 1200;
        if (navListItems.length >= MAX_NUMBER_OF_ELEMENTS_NOT_TO_BREAK && shouldFirstElementBeHidden) {
            navListItems[0].classList.add('hidden');
        }
        else {
            navListItems[0].classList.remove('hidden');
        }
    };
    hideFirstElement();
    window.addEventListener('resize', () => {
        hideFirstElement();
    });
}
