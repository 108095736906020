var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { isCookieBannerOpen } from "./cookie";
export function initVideoGrid() {
    return __awaiter(this, void 0, void 0, function* () {
        const iFrameWrappers = document.querySelectorAll('.video__iframe__wrapper');
        const cookieBanner = document.querySelector('.cookie-banner');
        const cookieButton = document.querySelector('.cookie-button');
        const cookieOverlay = document.querySelector('#cookie-overlay');
        const cookieYoutube = document.querySelector("input[id^='YT']");
        const cookieModal = document.querySelector('.video__section__modal');
        const overlay = document.querySelector('.overlay');
        if (iFrameWrappers) {
            iFrameWrappers.forEach((wrapper) => {
                const videoID = wrapper.dataset.video;
                const thumbnailWrapper = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.video__iframe__thumbnail');
                const playButton = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelector('.video__youtube__modal');
                playButton === null || playButton === void 0 ? void 0 : playButton.addEventListener('click', () => {
                    if (!localStorage.getItem(cookieYoutube.getAttribute('id'))) {
                        cookieModal.classList.add('active');
                        overlay.classList.add('active');
                    }
                    // Create an iframe with javascript
                    else {
                        thumbnailWrapper.style.display = 'none';
                        wrapper.innerHTML = `<iframe width="560" height="315" class="video__wrapper__iframe__video" src="https://www.youtube-nocookie.com/embed/${videoID}?autoplay=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
                    }
                });
            });
        }
        if (cookieModal) {
            const closeModalButton = cookieModal.querySelector('.video__section__modal-close');
            const openCookiesButton = cookieModal.querySelector('.video__section__modal-cookie');
            closeModalButton.addEventListener('click', () => {
                cookieModal.classList.remove('active');
                overlay.classList.remove('active');
            });
            openCookiesButton.addEventListener('click', () => {
                cookieModal.classList.remove('active');
                overlay.classList.remove('active');
                cookieBanner.classList.add('active');
                isCookieBannerOpen();
                cookieOverlay.classList.add('active');
                cookieButton.classList.remove('active');
                cookieYoutube.focus();
            });
        }
    });
}
