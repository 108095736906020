var FORM_PATHS;
(function (FORM_PATHS) {
    FORM_PATHS["GESCHWISTERBONUS"] = "/geschwisterbonus";
    FORM_PATHS["SCHULDGELDERMAESSIGUNG"] = "/schulgeldermaessigung";
})(FORM_PATHS || (FORM_PATHS = {}));
const { pathname } = new URL(window.location.href);
export default class KidsFieldset {
    constructor(fieldset, index, options) {
        var _a, _b, _c;
        this.index = index;
        this.options = options;
        this.changeFormNoticeSent = false;
        const isVisible = index < ((_a = options.initiallyVisibleCount) !== null && _a !== void 0 ? _a : 0);
        this.element = document.querySelector(`#${fieldset}`);
        if (!this.element) {
            throw new Error(`Cannot find element with id ${fieldset}.`);
        }
        this.actionsElement = this.createActionsElement();
        this.inputs = this.element.querySelectorAll('input, select');
        if (!isVisible) {
            this.element.style.display = 'none';
        }
        if (options.initiallyVisibleCount - 1 > index) {
            this.actionsElement.style.display = 'none';
        }
        // add a button at the end of each fieldset that will show the next fieldset
        if (1 === index) {
            if (pathname === FORM_PATHS.GESCHWISTERBONUS) {
                const text = document.createElement('p');
                text.innerHTML = `Zur zusätzlichen Beantragung der Schuldgeldermäßigung klicken Sie
 <a href="/schulgeldermaessigung">hier</a>.`;
                text.style.color = 'red';
                // Add an event listener to the anchor tag
                (_b = text.querySelector('a')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', function (event) {
                    const isConfirmed = confirm('Sie verlassen nun die Seite und die Dateien werden gelöscht und nicht gespeichert. Möchten Sie fortfahren?');
                    if (!isConfirmed) {
                        event.preventDefault();
                    }
                });
                this.element.append(text);
            }
        }
        if (0 === index) {
            if (pathname === FORM_PATHS.SCHULDGELDERMAESSIGUNG) {
                const text = document.createElement('p');
                text.innerHTML = 'Wenn Sie nur einen Geschwisterbonus ohne Einkommensberücksichtigung beantragen möchten, wechseln Sie bitte in das Formular <a href="/geschwisterbonus">Geschwisterbonus</a>.';
                text.style.color = 'red';
                // Add an event listener to the anchor tag
                (_c = text.querySelector('a')) === null || _c === void 0 ? void 0 : _c.addEventListener('click', function (event) {
                    const isConfirmed = confirm('Sie verlassen nun die Seite und die Dateien werden gelöscht und nicht gespeichert. Möchten Sie fortfahren?');
                    if (!isConfirmed) {
                        event.preventDefault();
                    }
                });
                this.element.append(text);
            }
        }
    }
    get previousFieldset() {
        var _a;
        return (_a = this.options.fieldsets[this.index - 1]) !== null && _a !== void 0 ? _a : null;
    }
    get nextFieldset() {
        var _a;
        return (_a = this.options.fieldsets[this.index + 1]) !== null && _a !== void 0 ? _a : null;
    }
    createActionsElement() {
        var _a, _b;
        const actions = [];
        if (this.nextFieldset) {
            actions.push('<button class="form-add-child btn" type="button">+ weiteres Kind hinzufügen</button>');
        }
        if (this.index >= this.options.initiallyVisibleCount) {
            actions.push('<button class="form-remove-child btn" type="button">- entfernen</button>');
        }
        const actionsElement = document.createElement('div');
        actionsElement.innerHTML = actions.join('');
        this.element.appendChild(actionsElement);
        if (this.nextFieldset) {
            (_a = this.element.querySelector('.form-add-child')) === null || _a === void 0 ? void 0 : _a.addEventListener('click', (e) => {
                e.preventDefault();
                this.showNextInstance();
            });
        }
        if (0 !== this.index) {
            (_b = this.element.querySelector('.form-remove-child')) === null || _b === void 0 ? void 0 : _b.addEventListener('click', (e) => {
                e.preventDefault();
                this.hide();
                this.activatePreviousInstance();
            });
        }
        return actionsElement;
    }
    show() {
        this.element.style.display = 'block';
        this.element.classList.add('active');
        this.actionsElement.style.display = 'block';
        this.inputs.forEach((input, index) => {
            if (input.tagName === 'SELECT') {
                input.setCustomValidity('Bitte wählen Sie eine Option aus');
                input.addEventListener('change', () => {
                    if (input.value == 'Bitte Schule wählen') {
                        input.setCustomValidity('Bitte wählen Sie eine Option aus');
                    }
                    else {
                        input.setCustomValidity('');
                    }
                });
            }
            if (index < 4) {
                input.required = true;
            }
        });
    }
    hide() {
        this.element.style.display = 'none';
        this.element.classList.remove('active');
        this.inputs.forEach((input, index) => {
            if (index < 4) {
                input.required = false;
            }
        });
    }
    showNextInstance() {
        if (!this.nextFieldset) {
            return console.error('Cannot show next fieldset, because there is none.');
        }
        this.element.classList.remove('active');
        this.actionsElement.style.display = 'none';
        this.nextFieldset.show();
    }
    activatePreviousInstance() {
        var _a;
        (_a = this.previousFieldset) === null || _a === void 0 ? void 0 : _a.show();
    }
}
