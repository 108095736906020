/**
 * @author Manuel Sanchez <manuel@webschuppen.com>
 * @version 1.0.0
 *
 * @description
 * This function will create a link out of an element that has @ and . in it
 * I made it work so that then it takes the 'td' elements inside the element
 *
 * @param string selector - The selector that will be used to find the elements
 * @return void - will do a replacement of the element with the link
 *
 * @example
 * initEmailCreator('.ce-table')
 */
export const initEmailCreator = (selector) => {
    const elements = document.querySelectorAll(selector);
    elements.forEach((element) => {
        const children = element.querySelectorAll('td');
        children.forEach((child) => {
            const text = child.textContent;
            if (text && text.indexOf('@') !== -1 && text.indexOf('.') !== -1) {
                child.innerHTML = `<a href="mailto:${text}">${text}</a>`;
            }
        });
    });
};
