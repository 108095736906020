import FormErrorController from '../FormErrorController';
export class RadioSelectionClearButton {
    constructor(selector, radioInputsSelector, { inputsToClearErrorsSelector }) {
        this.selector = selector;
        this.radioInputsSelector = radioInputsSelector;
        this.radioInputs = document.querySelectorAll(this.radioInputsSelector);
        this.inputsToClearErrors =
            document.querySelectorAll(inputsToClearErrorsSelector || '');
    }
    createClearButton() {
        const button = document.createElement('button');
        button.classList.add('clear-button', 'btn');
        button.type = 'button';
        button.innerHTML = `
      <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <defs>
              <path d="M3.088 3.088a.583.583 0 0 1 .824 0L7 6.175l3.088-3.087a.584.584 0 0 1 .744-.068l.08.068a.583.583 0 0 1 0 .824L7.825 7l3.087 3.088a.584.584 0 0 1 .068.744l-.068.08a.583.583 0 0 1-.824 0L7 7.825l-3.088 3.087a.584.584 0 0 1-.744.068l-.08-.068a.583.583 0 0 1 0-.824L6.175 7 3.088 3.912a.584.584 0 0 1-.068-.744z" id="a"/>
          </defs>
          <use fill="#fff" fill-rule="nonzero" xlink:href="#a"/>
      </svg>
      `;
        button.addEventListener('click', () => {
            this.clearSelections();
            this.toggleClearButton();
        });
        this.button = button;
        this.element.parentElement.parentElement.firstElementChild.appendChild(button);
    }
    toggleClearButton() {
        if (Array.from(this.radioInputs).some(input => input.checked)) {
            if (!this.button)
                this.createClearButton();
        }
        else {
            this.removeButton();
        }
    }
    removeButton() {
        var _a;
        (_a = this.button) === null || _a === void 0 ? void 0 : _a.remove();
        this.button = undefined;
    }
    clearSelections() {
        for (const radioInput of this.radioInputs) {
            radioInput.checked = false;
            radioInput.removeAttribute('required');
        }
        for (const input of this.inputsToClearErrors) {
            input.removeAttribute('required');
            FormErrorController.removeError(input);
        }
    }
    setupListeners() {
        for (const radioInput of this.radioInputs) {
            radioInput.addEventListener('change', () => this.toggleClearButton());
        }
    }
    start() {
        this.element = document.querySelector(this.selector);
        if (!this.element) {
            console.error(`Element with selector '${this.selector}' not found.`);
            return;
        }
        this.toggleClearButton();
        this.setupListeners();
    }
}
