var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const cookieButton = document.querySelector('.cookie-button');
const cookieBanner = document.querySelector('.cookie-banner');
const cookieAcceptOnlyRequiredButton = document.querySelector('.cookie-accept-only-required');
const cookieAcceptButton = document.querySelector('.cookie-accept');
const cookieParameters = document.querySelectorAll('.cookie-parameter');
const cookieOverlay = document.querySelector('#cookie-overlay');
const cookieLinks = document.querySelectorAll('.cookie-layer-menu a');
let hasInteractedWithCookieBanner = localStorage.getItem('hasInteractedWithCookieBanner');
const entranceTime = 1000;
export function initCookie() {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const inputDefaultId = (_a = document === null || document === void 0 ? void 0 : document.querySelector('.cookie-parameter__input-default')) === null || _a === void 0 ? void 0 : _a.getAttribute('id');
        //   Handle analytics from Matomo
        // if (
        //   localStorage.getItem("Statistik-id", true) ||
        //   localStorage.getItem("Matomo-id", true)
        // ) {
        //   addMatomoAnalytics();
        // }
        if (cookieBanner) {
            // Verify that only one overlay is possible at the same time: either popup or cookie one
            if (localStorage.getItem(inputDefaultId) === 'true') {
                cookieButton.classList.add('active');
            }
            else if (sessionStorage.getItem('popup') && !localStorage.getItem(inputDefaultId)) {
                setTimeout(function () {
                    cookieOverlay.classList.add('active');
                    cookieBanner.classList.add('active');
                    isCookieBannerOpen();
                }, entranceTime);
            }
            else {
                if (!hasInteractedWithCookieBanner) {
                    setTimeout(function () {
                        cookieBanner.classList.add('active');
                        isCookieBannerOpen();
                    }, entranceTime);
                }
                else {
                    cookieButton.classList.add('active');
                }
            }
            cookieButton.addEventListener('click', () => {
                cookieBanner.classList.add('active');
                isCookieBannerOpen();
                cookieButton.classList.remove('active');
                cookieOverlay.classList.add('active');
            });
            function handleCookieBanner(isAccept) {
                cookieBanner.classList.remove('active');
                isCookieBannerOpen();
                cookieOverlay.classList.remove('active');
                cookieButton.classList.add('active');
                if (isAccept) {
                    localStorage.setItem(inputDefaultId, 'true');
                    cookieParameters.forEach((parameter) => {
                        const input = parameter.querySelector('.cookie-parameter__input');
                        const labelPre = parameter.querySelector('.cookie-parameter__label');
                        const label = labelPre.htmlFor;
                        if (input.checked === true) {
                            localStorage.setItem(label, 'true');
                        }
                        else {
                            localStorage.removeItem(label);
                        }
                    });
                }
                else {
                    cookieParameters.forEach((parameter) => {
                        const input = parameter.querySelector('.cookie-parameter__input');
                        const labelPre = parameter.querySelector('.cookie-parameter__label');
                        const label = labelPre.htmlFor;
                        if (input.classList.contains('cookie-parameter__input-default')) {
                            localStorage.setItem(label, 'true');
                        }
                        else {
                            if (localStorage.getItem(label) === 'true' || input.checked === true) {
                                switchState(input);
                            }
                            localStorage.removeItem(label);
                        }
                    });
                }
                // create a new event object
                const myEvent = new Event("cookiesUpdated" /* EVENTS.COOKIES_UPDATED */);
                // dispatch the event on the document object
                document.dispatchEvent(myEvent);
            }
            cookieAcceptButton.addEventListener('click', () => {
                handleCookieBanner(true);
                hasInteractedWithCookieBanner = 'true';
                localStorage.setItem('hasInteractedWithCookieBanner', hasInteractedWithCookieBanner);
            });
            cookieAcceptOnlyRequiredButton.addEventListener('click', () => {
                handleCookieBanner(false);
                hasInteractedWithCookieBanner = 'true';
                localStorage.setItem('hasInteractedWithCookieBanner', hasInteractedWithCookieBanner);
            });
            const switchState = (input) => input.click();
            cookieParameters.forEach((parameter) => {
                const input = parameter.querySelector('.cookie-parameter__input');
                const labelElement = parameter.querySelector('.cookie-parameter__label');
                const labelPre = parameter.querySelector('.cookie-parameter__label');
                const label = labelPre.htmlFor;
                if (localStorage.getItem(label) === 'true') {
                    switchState(input);
                }
                // This only applies to firefox
                if (navigator.userAgent.match(/firefox|fxios/i)) {
                    labelElement.addEventListener('click', () => {
                        switchState(input);
                    });
                }
                input.addEventListener('click', () => {
                    switchState(input);
                });
            });
        }
    });
}
export function isCookieBannerOpen() {
    if (cookieBanner.classList.contains('active')) {
        cookieAcceptOnlyRequiredButton.tabIndex = 0;
        cookieAcceptButton.tabIndex = 0;
        cookieParameters.forEach((parameter) => {
            parameter.querySelector('input').tabIndex = 0;
        });
        cookieLinks.forEach((link) => {
            link.tabIndex = 0;
        });
        cookieBanner.removeAttribute('aria-hidden');
    }
    else {
        cookieAcceptOnlyRequiredButton.tabIndex = -1;
        cookieAcceptButton.tabIndex = -1;
        cookieParameters.forEach((parameter) => {
            parameter.querySelector('input').tabIndex = -1;
        });
        cookieLinks.forEach((link) => {
            link.tabIndex = -1;
        });
        cookieBanner.setAttribute('aria-hidden', 'true');
    }
}
export function initCookieDetails() {
    return __awaiter(this, void 0, void 0, function* () {
        const detailsCookies = document.querySelector('[data-details-cookies]');
        const openButton = document.querySelector('[data-open-details-cookies]');
        const closeButton = document.querySelector('[data-close-details-cookies]');
        if (!openButton || !detailsCookies)
            return;
        openButton.addEventListener('click', () => {
            detailsCookies.classList.add('active');
        });
        closeButton.addEventListener('click', () => {
            detailsCookies.classList.remove('active');
        });
    });
}
