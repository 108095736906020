export function initDesktopMenu() {
    getDropdowns().forEach((dropdown) => {
        let toggleElement = dropdown.querySelector('button');
        if (!(toggleElement === null || toggleElement === void 0 ? void 0 : toggleElement.dataset.noLink)) {
            toggleElement = dropdown.querySelector('svg.dropdown-arrow.desktop');
        }
        toggleElement.addEventListener('click', (e) => toggleDropdown(e));
        toggleElement.addEventListener('keydown', (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                toggleDropdown(e);
                e.preventDefault();
            }
        });
    });
}
function getDropdowns() {
    return Array.from(document.querySelectorAll('.dropdown-item:has(svg.dropdown-arrow.desktop)'));
}
function toggleDropdown(e) {
    const dropdown = e.currentTarget;
    let dropdownArrow;
    let dropdownMenu;
    let dropdownItem;
    if (dropdown.classList.contains('dropdown-arrow')) {
        dropdownArrow = dropdown;
        dropdownArrow.classList.toggle('dropdown-arrow--open');
        dropdownMenu = dropdown.parentElement.nextElementSibling;
        dropdownItem = dropdown.parentElement;
    }
    else {
        dropdownArrow = dropdown.parentElement.querySelector('svg.dropdown-arrow.desktop');
        dropdownArrow.classList.toggle('dropdown-arrow--open');
        dropdownMenu = dropdown.parentElement.nextElementSibling;
        dropdownItem = dropdown.parentElement;
    }
    dropdownMenu.classList.toggle('dropdown-menu--open');
    dropdownItem.classList.toggle('dropdown-item--open');
    const child = dropdownMenu.firstElementChild;
    const isOpen = dropdownMenu.classList.contains('dropdown-menu--open');
    if (isOpen) {
        dropdownMenu.style.height = (child === null || child === void 0 ? void 0 : child.getBoundingClientRect().height) + 'px';
        addTabIndex(dropdownMenu);
    }
    else {
        dropdownMenu.style.height = '0px';
        removeTabIndex(dropdownMenu);
    }
}
const removeTabIndex = (menu) => {
    const links = Array.from(menu.querySelectorAll('a'));
    links.forEach((link) => {
        link.setAttribute('tabindex', '-1');
    });
};
const addTabIndex = (menu) => {
    const links = Array.from(menu.querySelectorAll('a'));
    links.forEach((link) => {
        link.setAttribute('tabindex', '0');
    });
};
