export const initCheckErrorInForm = (selector) => {
    const elements = document.querySelectorAll(selector);
    if (elements.length > 0) {
        setTimeout(() => {
            const firstElement = elements[0];
            window.scrollTo({
                top: firstElement.offsetTop - 300,
                behavior: 'smooth'
            });
        }, 500);
    }
};
