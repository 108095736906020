var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
const DELAY = 300;
import gsap from 'gsap';
export function initIntroAnimation() {
    return __awaiter(this, void 0, void 0, function* () {
        const elements = document.querySelectorAll('.intro');
        if (elements) {
            gsap.utils.toArray(elements).forEach((element) => {
                const container = element.querySelector('.intro__outer');
                const elementTimeline = gsap.timeline({
                    defaults: { duration: 1.2, ease: 'power2.out' },
                    paused: true
                });
                elementTimeline.to(container, {
                    opacity: 1,
                    x: 0
                });
                setTimeout(() => {
                    elementTimeline.play();
                }, DELAY);
            });
        }
    });
}
