export const initGoogleMaps = (container) => {
    const iframeWrapper = document.querySelector(container);
    if (!iframeWrapper)
        return;
    let iframe = iframeWrapper.querySelector('iframe');
    const isGmapsCookie = localStorage.getItem("GM-id" /* COOKIES.GOOGLE_MAPS */);
    if (!iframeWrapper && iframe)
        return;
    // Get the dataset
    const dataIframe = iframeWrapper.dataset.iframe;
    // Split the code and get the src
    const src = dataIframe.split('src="')[1].split('"')[0];
    // This should only happen if the cookie is set
    const cookieBanner = document.querySelector('.cookie-banner');
    const cookieButton = document.querySelector('.cookie-button');
    // Save html in case the user doesn't accept the cookies, but wants to see the map later
    const html = iframeWrapper.innerHTML;
    if (isGmapsCookie) {
        iframe.src = src;
    }
    else {
        iframeWrapper.innerHTML = `
      <p class="open-cookie-wrapper">Bitte, <a href="/" class="open-cookie">akzeptieren</a> Sie die Google Maps Cookies, um die Karte zu sehen.<p>
  `;
    }
    document.addEventListener("cookiesUpdated" /* EVENTS.COOKIES_UPDATED */, () => {
        const isGmapsCookie = localStorage.getItem("GM-id" /* COOKIES.GOOGLE_MAPS */);
        if (isGmapsCookie) {
            iframeWrapper.innerHTML = html;
            iframe = iframeWrapper.querySelector('iframe');
            iframe.src = src;
        }
        else {
            iframeWrapper.innerHTML = `
          <p class="open-cookie-wrapper">Bitte, <a href="/" class="open-cookie">akzeptieren</a> Sie die Google Maps Cookies, um die Karte zu sehen.<p>
      `;
        }
    });
    const cookieOpenElements = document.querySelectorAll('.open-cookie');
    const openCookieBanner = (e) => {
        e.preventDefault();
        cookieBanner.classList.add('active');
        cookieButton.classList.remove('active');
    };
    cookieOpenElements.forEach((element) => {
        element.addEventListener('click', openCookieBanner);
    });
};
