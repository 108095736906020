import FormErrorController from '../FormErrorController';
/**
 * Sets a field as required
 * @param selector The selector of the field, must be an input, select or textarea
 */
export default class RequiredField {
    constructor(selector, message, options) {
        this.selector = selector;
        this.message = message;
        this.options = options;
        this.element = document.querySelector(selector);
        if (!this.element) {
            console.error('RequiredField: Element not found', selector);
            return;
        }
    }
    start() {
        this.element.required = true;
        this.element.addEventListener('change', () => this.reportValidity());
    }
    reportValidity() {
        if (this.isValid) {
            FormErrorController.removeError(this.element);
        }
        else {
            FormErrorController.addError(this.element, this.message);
            return this.message;
        }
    }
    get isValid() {
        return this.element.value !== this.options.placeholder && Boolean(this.element.value);
    }
    onSubmit() {
        const error = this.reportValidity();
        if (error) {
            try {
                this.element.focus();
            }
            catch (e) {
                console.error('RequiredField: Failed to focus element', e);
            }
            return error;
        }
    }
}
