var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function initSidebar() {
    return __awaiter(this, void 0, void 0, function* () {
        const sidebar = document.querySelector('.sidebar');
        const sidebarContentItems = document.querySelectorAll('.sidebar__item-content');
        const width = window.innerWidth;
        const heroSize = 1200;
        if (!sidebar)
            return;
        if (width >= heroSize)
            sidebar.classList.add('active');
        function appearOnScroll() {
            if (width > heroSize)
                return;
            if (window.pageYOffset > 200) {
                sidebar.classList.add('active');
            }
            else {
                sidebar.classList.remove('active');
            }
        }
        window.addEventListener('scroll', appearOnScroll);
        sidebarContentItems === null || sidebarContentItems === void 0 ? void 0 : sidebarContentItems.forEach((item) => {
            const openButton = item.querySelector('.sidebar__icon');
            const closeButton = item.querySelector('.sidebar__close');
            const content = item.querySelector('.sidebar__content');
            closeButton.addEventListener('click', () => {
                content.classList.remove('sidebar__content--open');
            });
            item.addEventListener('mouseleave', () => {
                content.classList.remove('sidebar__content--open');
            });
            openButton.addEventListener('mouseenter', () => {
                sidebarContentItems.forEach((item) => {
                    item
                        .querySelector('.sidebar__content')
                        .classList.remove('sidebar__content--open');
                    return;
                });
                content.classList.add('sidebar__content--open');
            });
        });
    });
}
