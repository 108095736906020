export const initHeader = () => {
    const headerParts = document.querySelectorAll('.header, .header__nav, .header__nav__socials, .header__nav__content, .brand__logo, .search-box');
    const updateScrollClass = () => {
        const elements = Array.from(document.querySelectorAll('.hero__img, .header'));
        if (window.scrollY) {
            elements.forEach((element) => {
                if (!element.classList.contains('scrolled-a-bit')) {
                    element.classList.add('scrolled-a-bit');
                }
            });
        }
        else {
            elements.forEach((element) => {
                element.classList.remove('scrolled-a-bit');
            });
        }
        if (window.scrollY > 200) {
            addScrolledClass();
        }
        else if (window.scrollY < 50) {
            removeScrolledClass();
        }
    };
    function addScrolledClass() {
        headerParts.forEach((header) => {
            header.classList.add('scrolled');
        });
    }
    function removeScrolledClass() {
        headerParts.forEach((header) => {
            header.classList.remove('scrolled');
        });
    }
    window.addEventListener('scroll', updateScrollClass);
    updateScrollClass();
};
