import Marquee from 'vanilla-marquee';
export function initMarqueeSpanTransformation() {
    const marquees = document.querySelectorAll('.marquee');
    if (!marquees)
        return;
    marquees.forEach((marquee) => {
        console.log(marquee.textContent);
        marquee.innerHTML = marquee.textContent.replace(/\s+\+ \+ \+\s+/g, '&nbsp;&nbsp;+ + +&nbsp;&nbsp;');
        new Marquee(marquee, {
            gap: 0,
            speed: 30,
            pauseOnHover: true,
            startVisible: true,
            duplicated: true,
            recalcResize: true,
        });
    });
}
