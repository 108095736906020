/**
 * We are using the login form from Typo3 here by default
 * but we have to hide a couple of elements and set the username (user1)
 * We are basically hiding the user input, the headline, the paragraph and some other elements
 */
export function initProtectedArea({ slugProtectedArea = '/interner-bereich' }) {
    // Get the username field
    const usernameField = document.querySelector('input[name="user"]');
    // Get the headline and paragraph that come from the form by default
    const headline = document.querySelector('.frame-type-felogin_login h3');
    const paragraph = document.querySelector('.frame-type-felogin_login p');
    // Access the container of the user to hide it
    const containerOfUser = usernameField === null || usernameField === void 0 ? void 0 : usernameField.closest('div');
    // Add a message after the containerOfUser 
    const messageAfterUser = document.createElement('div');
    messageAfterUser.innerHTML = `
    <p>Um in den internen Bereich zu gelangen, geben Sie bitte das Passwort ein.</p>`;
    containerOfUser === null || containerOfUser === void 0 ? void 0 : containerOfUser.insertAdjacentElement('afterend', messageAfterUser);
    // Access the logout button and hide the previous element
    const inputLogout = document.querySelector('input[value="Logout"]');
    const divLogout = inputLogout === null || inputLogout === void 0 ? void 0 : inputLogout.closest('div');
    const divPrevious = divLogout === null || divLogout === void 0 ? void 0 : divLogout.previousElementSibling;
    // Insert a <p> before the inputLogout now hehe
    const message = document.createElement('div');
    message.innerHTML = `
    <p>Sie sind nun eingeloggt.</p>
    <p>Zum internen Bereich gelangen Sie <a href="${slugProtectedArea}">hier</a>.</p>`;
    divLogout === null || divLogout === void 0 ? void 0 : divLogout.insertAdjacentElement('beforebegin', message);
    const inputLogoutDE = document.querySelector('input[value="Abmelden"]');
    const divLogoutDE = inputLogoutDE === null || inputLogoutDE === void 0 ? void 0 : inputLogoutDE.closest('div');
    const divPreviousDE = divLogoutDE === null || divLogoutDE === void 0 ? void 0 : divLogoutDE.previousElementSibling;
    // Insert a <p> before the inputLogout now hehe
    const messageDE = document.createElement('div');
    messageDE.innerHTML = `
    <p>Sie sind nun eingeloggt.</p>
    <p>Zum internen Bereich gelangen Sie <a href="${slugProtectedArea}">hier</a>.</p>`;
    divLogoutDE === null || divLogoutDE === void 0 ? void 0 : divLogoutDE.insertAdjacentElement('beforebegin', message);
    if (usernameField) {
        usernameField.value = 'user1';
        usernameField.setAttribute('type', 'hidden');
        usernameField.setAttribute('readonly', 'readonly');
    }
    if (containerOfUser) {
        containerOfUser.style.display = 'none';
    }
    if (paragraph) {
        paragraph.style.display = 'none';
    }
    if (headline) {
        headline.style.display = 'none';
    }
    if (divPrevious) {
        divPrevious.style.display = 'none';
    }
    if (divPreviousDE) {
        divPreviousDE.style.display = 'none';
    }
}
