import gsap from 'gsap';
class ShareButton extends HTMLElement {
    constructor() {
        super();
        this._shadowRoot = this.attachShadow({ mode: 'open' });
        this._render();
    }
    _render() {
        // add styling
        const styleTemplate = document.querySelector('.share-style-template');
        this._shadowRoot.appendChild(styleTemplate.content.cloneNode(true));
        // create button
        const buttonTemplate = document.querySelector('.share-button-template');
        this._shadowRoot.appendChild(buttonTemplate.content.cloneNode(true));
        // create menu
        const menuTemplate = document.querySelector('.share-menu-template');
        this._shadowRoot.appendChild(menuTemplate.content.cloneNode(true));
        this._menu = this._shadowRoot.querySelector('.share-menu');
        // toggle menu when clicking the button
        const shareButton = this._shadowRoot.querySelector('.share-button');
        shareButton.addEventListener('click', () => toggleMenu(this._menu));
    }
}
const toggleMenu = (menu) => {
    const isMenuVisible = menu.style.display === 'grid';
    if (isMenuVisible) {
        hideMenu(menu);
    }
    else {
        showMenu(menu);
    }
};
const showMenu = (menu) => {
    menu.style.display = 'grid';
    // Put the menu items in the right position
    const menuItems = Array.from(menu.querySelectorAll('.share-menu-item'));
    // Desktop
    let menuItemPositions = [
        { top: '-1.65rem', left: '-2rem' },
        { top: '-0.1rem', left: '-1.05rem' },
        { top: '0.15rem', left: '0.75rem' }
    ];
    if (innerWidth < 1024) {
        // Mobile
        menuItemPositions = [
            { top: '-1.8rem', left: '-2.4rem' },
            { top: '-3.7rem', left: '-1.9rem' },
            { top: '-4.1rem', left: '0.1rem' }
        ];
    }
    gsap.set(menuItems, { opacity: 0, scale: 0.5 });
    menuItems.forEach((item, index) => {
        gsap.to(item, {
            opacity: 1,
            scale: 1,
            duration: 0.3,
            delay: index * 0.1,
            ease: 'power2.out',
            onStart: () => {
                item.style.top = menuItemPositions[index].top;
                item.style.left = menuItemPositions[index].left;
            }
        });
    });
};
const hideMenu = (menu) => {
    // Make the menu items disappear
    const menuItems = Array.from(menu.querySelectorAll('.share-menu-item'));
    menuItems.forEach((item, index) => {
        gsap.to(item, {
            opacity: 0,
            scale: 0.5,
            duration: 0.3,
            delay: (menuItems.length - index - 1) * 0.1,
            ease: 'power2.out',
            onComplete: () => {
                menu.style.display = 'none';
            }
        });
    });
};
export function initShare() {
    // define the new element, so it can be used in HTML
    customElements.define('share-button', ShareButton);
}
