export default class SetRequiredIfCheckboxChecked {
    constructor(inputSelector, checkboxSelector) {
        this.inputSelector = inputSelector;
        this.checkboxSelector = checkboxSelector;
    }
    handleCheckboxChange() {
        console.debug('SetRequiredIfCheckboxChecked: input required set to', this.inputElement.required);
        this.inputElement.required = this.checkboxElement.checked;
    }
    start() {
        console.debug('SetRequiredIfCheckboxChecked: start');
        this.inputElement = document.querySelector(this.inputSelector);
        this.checkboxElement = document.querySelector(this.checkboxSelector);
        if (!this.inputElement || !this.checkboxElement) {
            throw new Error('Input or checkbox element not found');
        }
        if (this.checkboxElement.type !== 'checkbox' && this.checkboxElement.type !== 'radio') {
            throw new Error('Input element must be a checkbox or radio button');
        }
        console.debug('SetRequiredIfCheckboxChecked: input element', this.inputElement, 'checkbox element', this.checkboxElement);
        this.handleCheckboxChange();
        if (this.checkboxElement.type === 'checkbox') {
            this.checkboxElement.addEventListener('change', this.handleCheckboxChange.bind(this));
        }
        else if (this.checkboxElement.type === 'radio') {
            this.checkboxElement.parentElement.parentElement
                .addEventListener('change', this.handleCheckboxChange.bind(this));
        }
    }
}
