import FormErrorController from '../FormErrorController';
export default class RequireAtLeastOneFile {
    constructor() {
    }
    onSubmit() {
        return this.reportValidity();
    }
    reportValidity() {
        const fileInputs = document.querySelectorAll('input[type="file"]');
        let isFileSelected = false;
        for (let i = 0; i < fileInputs.length; i++) {
            if (fileInputs[i].files && fileInputs[i].files.length > 0) {
                isFileSelected = true;
                break;
            }
        }
        if (isFileSelected) {
            // all good
            FormErrorController.removeError('#schulgeldermaessigung-1207-checkbox-2');
        }
        else {
            const errorMessage = 'Bitte kreuzen Sie bei Punkt 2. entsprechend an und laden Sie die Nachweise hoch. ' +
                'Ohne Angaben und Nachweise ist keine Ermäßigung möglich.';
            const form = document.querySelector('#schulgeldermaessigung-1207-checkbox-2');
            if (!form)
                return console.error('RequireAtLeaseOneField: Form not found');
            FormErrorController.addError(form, errorMessage);
            return errorMessage;
        }
    }
    start() {
        const fileInputs = document.querySelectorAll('input[type="file"]');
        fileInputs.forEach((fileInput) => {
            fileInput.addEventListener('change', () => {
                this.reportValidity();
            });
        });
    }
}
